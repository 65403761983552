// eslint-disable-next-line
import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";

import PvtBackground from "../components/mainPage/PvtBackground";
import HeadIcon from "../components/mainPage/HeadIcon";
import PersonalMsg from "../components/mainPage/PersonalMsg";
import List from "../components/mainPage/list/List";
import {useAccount} from "wagmi";
// eslint-disable-next-line
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
// eslint-disable-next-line
import {blankMsg, setLinkTreeEthMsg, setPriority, setUserMsg} from "../store/reducer/homeMsg";
// eslint-disable-next-line
import {setAddressMsg} from "../store/reducer/addressMsg";
import UserSearching from "../components/mainPage/UserSearching";
import userTypeCheck from "../utils/UserType";
import UserNotFound from "../components/mainPage/UserNotFound";
import axios from "axios";
import {ethType, twitterNameType} from "../types/UserTypeDefinition";


const Section = styled.section`
  position: relative;
  margin: 0 auto;
  width: 72%;
  max-width: 960px;
  height: 1000px;
  //background-color: #bfa;
  @media screen and (max-width: 1300px) {
    width: 95%;
  }
  @media screen and (max-width: 1000px) {
    width: 450px;
  }
  @media screen and (max-width: 600px) {
    width: 90%;
  }
`

const MainPage = () => {

    //区分看的用户，有没有编辑权
    const account = useAccount();
    const location = useLocation();
    const dispatch = useDispatch();
    //用户模型
    const homeMsg = useSelector(state => state.home)

    console.log(homeMsg)
    //推特查找用户
    const twitterForward = async (account) => {
        axios.get(`https://bk9g9xe5x7.execute-api.ca-central-1.amazonaws.com/test/forward/2/users/by/username/${account}`)
            .then((res) => {
                console.log("res.data", res.data)
                if (res.data.data) {
                    dispatch(setUserMsg({twitter_handle:account,areacode:twitterNameType}))
                } else if (res.data.errors) {
                    dispatch(setUserMsg({areacode: -1}))
                }
            }).catch((error) => {
            console.log(error)
        })
    }
    //访问用户
     const visit = async ( account, type) => {
        try {
            const response = await fetch(`https://yvxniuz5pe.execute-api.us-west-1.amazonaws.com/default/visit?account=${account}&type=${type}`, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                }
            })
            console.log("查过数据库")
            const data = await response.json();
            console.log("data:", data)
            //如果能查到
            if (data.status === 200||data.message === "account already exist") {
                dispatch(setUserMsg(data.user))
            } else {
                //如果查不到，钱包的查不到跳转空白，推特的查不到先查推特
                if (type===ethType){
                    dispatch(setUserMsg({eth_address:account,areacode:ethType}))
                } else if(type===twitterNameType){
                    twitterForward(account)
                } else{
                    //其它查不到返回连接钱包
                    dispatch(setUserMsg({areacode: -1}))
                }
            }
        } catch (e) {

        }
    }

    //到数据库里查找用户
    useEffect(() => {
        //区分用户类型
        const userType = userTypeCheck(location.pathname.substring(1))
        console.log("userType", userType)
        //如果设置了用户
        if (userType.type !== 0 || userType.type !== -1) {
            console.log("我运行了")
            visit(userType.name, userType.type)

        }
    }, [])
    //是否有编辑权
    useEffect(() => {
        console.log("改变编辑权")
        if (account.address === location.pathname.substring(1)) {
            dispatch(setPriority(true));
        } else (
            dispatch(setPriority(false))
        )
    },[account.address,location.pathname.substring(1)])

    return (
        <>
            {
                homeMsg.type === 0
                    ?
                    <UserSearching/>
                    :
                    homeMsg.type === -1
                        ?
                        <UserNotFound/> :
                        <>
                            <PvtBackground/>
                            <Section>
                                <HeadIcon/>
                                <PersonalMsg/>
                                <List/>
                            </Section>
                        </>
            }
        </>
    );
};

export default MainPage;