import React, {useEffect, useRef, useState} from 'react';
import styled, {keyframes, ThemeProvider} from "styled-components";
import starImg from "../assets/top_logo@2x.png"
import searchIcon from '../assets/icon_search@2x.png'
import walletIcon from '../imgs/azuki.png'
import copyImg from '../assets/btn_copy@2x.png'
import Address from "../components/Address"
import {MenuOut, Nomal, SearchExpand} from "../styles/Themes"
import CopyAddress from "../components/CopyAddress";
import copy from 'copy-to-clipboard';
import 'antd/dist/antd.css';
import { Dropdown, Menu } from "antd";

import {useDispatch, useSelector} from "react-redux";
import {useAccount,useDisconnect} from "wagmi";
import {setAddressMsg, setUidMsg} from "../store/reducer/addressMsg";
import {useAccountModal, useChainModal, useConnectModal} from "@rainbow-me/rainbowkit";
// eslint-disable-next-line
import {useGetFollowNumberQuery} from "../store/api/followNumberApi";

import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
// eslint-disable-next-line
import {ethType, twitterNameType} from "../types/UserTypeDefinition";
// eslint-disable-next-line
import MainPage from "./MainPage";
import {setUserMsg} from "../store/reducer/homeMsg";

const Section = styled.section`
  height: 78px;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  @media screen and (max-width: 600px) {
    padding: 0;
  }
`
const StarImg = styled.img`
  width: 101px;
  display: ${props => props.theme.starDisplay ? "block" : "none"};
  @media screen and (max-width: 600px) {
    margin-left: 5vw;
  }
`
const HeaderRight = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {

  }
`
const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 460px;
  @media screen and (max-width: 1000px) {
    max-width: 1460px;

  }
  @media screen and (max-width: 600px) {
    max-width: 1000px;
  }
`
const SearchImg = styled.img`
  position: relative;
  left: 50px;
  transform: scale(35%);
  z-index: 1;
  @media screen and (max-width: 600px) {
    display: ${props => props.theme.searchImgDisplay ? "block" : "none"};
    left: ${props => props.theme.searchImgLeft};
    transform: scale(100%);
    width: 28px;
    cursor: pointer;
  }
`
const expand = keyframes`
  from {
    width: 40vw;
  }
  to {
    ${props => props.theme.searchWidth}
  }
`
const Search = styled.input`

  width: ${props => props.theme.searchWidth};
  height: 40px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #9D9D9D;
  
  font-size: 18px;
  line-height: 40px;

  padding-left: 45px;

  ::placeholder {

    color: #ADADAD;
    font-size: 14px;
  }

  @media screen and (max-width: 1000px) {
    width: 50vw;
  }
  @media screen and (max-width: 750px) {
    width: 45vw
  }
  @media screen and (max-width: 680px) {
    width: 39vw
  }

  @media screen and (max-width: 600px) {
    width: ${props => props.theme.searchWidth};
    position: relative;
    display: ${props => props.theme.searchDisplay ? "block" : "none"};
    animation: ${expand} 0.4s ease-out;
  }
`
const Wallet = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    display: none;
  }
`
const AddressBorder = styled.div`

  width: 131px;
  height: 40px;
  border-radius: 12px;
  border: 2px solid #9D9D9D;

  padding-left: 20px;
  margin-left: 10px;

  display: flex;
  align-items: center;
  vertical-align: center;
  cursor: pointer;
`
const WalletIconContainer = styled.div`
  position: absolute;
  left: 107px;
  width: 48px;
  height: 48px;
  border: 2px solid #E5E3E8;
  border-radius: 50%;
  overflow: hidden;
  cursor:pointer;
  opacity:${props=>props.isLoadingFinished ? 1 : 0};
`
const WalletIcon = styled.img`
  width: 48px;
  transform: scale(110%);
`
const MenuImg = styled.img`
  display: none;
  width: 27px;
  position: absolute;
  right: 5vw;
  
  @media screen and (max-width: 600px) {
    display: block;
    margin-left: 20px;
    cursor: pointer;
  }
`
const slip = keyframes`
  from {
    height: 20px;
  }
  to {
    height: 93px;
  }
`
const AddressOutShade = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 93px;
  background: black;
  color: #ccc;
  animation: ${slip} 0.3s ease-out;
`
const AddressOutIconContainer = styled(WalletIconContainer)`
  left: 6.6vw;
  z-index: 2;
`
const AddressOut = styled.div`
  width: 80%;
  margin: 0 auto;
  height: 40px;
  position: relative;
  border-radius: 12px;
  border: 2px solid #CCC;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const CopyImgPosition = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 2;
  right: 20px;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border-radius: 3px;
  cursor: pointer;
`

const CopyImg = styled.img`
  width: 20px;
`


const Header = (props) => {
    //信息加载
    const homeMsg = useSelector(state => state.home);
    const addressMsg=useSelector(state => state.address)
    const location=useLocation()
    const account=useAccount();
    //钱包界面相关
    const {openConnectModal}=useConnectModal();
    const { openAccountModal } = useAccountModal();
    const { openChainModal } = useChainModal();
    const dispatch=useDispatch();

    const searchItem = useRef();
    const [theme, setTheme] = useState(Nomal);
    //访问用户
    const visit = async ( account, type) => {
        try {
            const response = await fetch(`https://yvxniuz5pe.execute-api.us-west-1.amazonaws.com/default/visit?account=${account}&type=${type}`, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                }
            })
            console.log("查过数据库")
            const data = await response.json();
            console.log("data:", data)
            //如果能查到
            if (data.status === 200||data.message === "account already exist") {
                dispatch(setUserMsg(data.user))
            } else {
                //如果查不到，钱包的查不到跳转空白
                if (type===ethType){
                    dispatch(setUserMsg({eth_address:account,areacode:ethType}))
                }  else{
                    //其它查不到返回连接钱包
                    dispatch(setUserMsg({areacode: -1}))
                }
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            window.onresize = () => {
                if (window.innerWidth > 600) {
                    setTheme(Nomal);
                }
            }
        }, 1000);
        return () => {
            clearTimeout(timer);
        }
    }, [window.innerWidth])
    useEffect(()=>{
        if (account.isConnected){
            dispatch(setAddressMsg(account.address))
        }else {dispatch(setAddressMsg(""))}
    },[account])
    //每当钱包切换时，发送请求拿到钱包uid
    useEffect(()=>{
        console.log("addressMsg:",addressMsg)
        if (account.isConnected) {
            console.log("要去重新设置uid")
            axios.get(`https://yvxniuz5pe.execute-api.us-west-1.amazonaws.com/default/register?account=${account.address}&type=${ethType}`)
                .then(
                    (res)=>{
                        dispatch(setUidMsg(res.data.user.uid))
                    }
                ).catch(
                (err)=>{
                    console.log(err)}
            )
        }
    },[account.address])
    //改变头部主体或者设置input框的焦点
    const searchImgHandler = () => {
        if (window.innerWidth <= 600 && theme === Nomal) {
            setTheme(SearchExpand);
        } else {
            searchItem.current.focus();
        }
    }
    const menuClickHandler = () => {
        if (theme === Nomal) {
            setTheme(MenuOut);
        } else {
            setTheme(Nomal);
        }
    }
    const copyHandler = e=> {
        e.stopPropagation();
        copy(addressMsg.Address);
    }
    const backhomeHandler=()=>{
        console.log("location",location.pathname.substring(1))
        console.log("address",account.address)
        if (location.pathname.substring(1)!==account.address){
            nav(account.address)
            visit(account.address,ethType)
        }
    }
    const {disconnect }=useDisconnect({
        //连接成功的逻辑
        onSuccess(){
            //个人主页退出钱包则跳转至连接钱包页面
            if (homeMsg.Priority){
                nav('/')
            }
        }
    });
    const nav=useNavigate();
    const signOutHandler=()=>{
        disconnect();
        //清空用户信息
        dispatch(setUidMsg(0))
    }
    //下拉菜单
    const menu=(
        <Menu
            items={[
                {
                    key:"1",
                    label:(
                        <div onClick={openChainModal}>
                            Chainlist
                        </div>
                    )
                },
                {
                    key:"2",
                    label:(
                        <div onClick={signOutHandler}>
                            sign out
                        </div>
                    )
                }
            ]}
        />
    )

    const estRef = useRef()
    const [isLoadingFinished,setIsLoadingFinished] = useState(false)
    useEffect(()=>{
      console.log('testw',estRef.current.src)

      var img = new Image()
      img.src = walletIcon
      img.onload = function(){
        setIsLoadingFinished(true)
      }

    },[estRef])

    return (
        <>
            <Section>
                <ThemeProvider theme={theme}>
                    <StarImg src={starImg}/>
                    <HeaderRight>
                        <SearchContainer>
                            <SearchImg src={searchIcon} onClick={searchImgHandler}/>
                            <Search ref={searchItem} type="text"
                                    placeholder="Search address , twitter, handle , etc here"/>
                        </SearchContainer>
                        <Wallet>
                            {account.isConnected?
                                    <Dropdown overlay={menu}>
                                            <AddressBorder>
                                                <Address>
                                                    {addressMsg.Address}
                                                </Address>
                                            </AddressBorder>
                                    </Dropdown>
                                :<AddressBorder onClick={openConnectModal}>
                                    Connect
                                </AddressBorder>
                                }
                            <WalletIconContainer onClick={backhomeHandler} isLoadingFinished={isLoadingFinished} >
                                <WalletIcon src={walletIcon} ref={estRef} alt={"profile photo"}/>
                            </WalletIconContainer>
                        </Wallet>
                        <MenuImg onClick={menuClickHandler} src={theme.menuImg}/>
                    </HeaderRight>
                </ThemeProvider>
            </Section>
            {theme.addressOut &&
                <AddressOutShade>
                    <AddressOutIconContainer onClick={backhomeHandler}>
                        <WalletIcon style={{"width": "52px"}} src={walletIcon}/>
                    </AddressOutIconContainer>
                    {account.isConnected?
                        <AddressOut onClick={openAccountModal}>
                        <Address
                            style={{"paddingLeft": "11vw"}}
                            pre={7} after={6}
                        >{addressMsg.Address}
                        </Address>
                        <CopyImgPosition onClick={copyHandler}>
                            <CopyAddress>
                                <CopyImg src={copyImg}/>
                            </CopyAddress>
                        </CopyImgPosition>
                    </AddressOut>
                        :
                    <AddressOut onClick={openConnectModal}>
                        <div style={{"paddingLeft": "11vw"}}>Connect</div>
                    </AddressOut>}
                </AddressOutShade>}
        </>
    );
};

export default Header;