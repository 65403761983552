import React from 'react';
import styled from "styled-components";
import shopLinkimg from "../assets/icon_visit@2x.png"

const Container=styled.div`
    cursor: pointer;
  &:hover{
    text-decoration-line: underline;
  }
`
const StoreLink=styled.a`
  color: #573FA3;
`
const StoreLinkImg=styled.img`
width: 10px;
  margin-left: 3px;
`

const VisitStore = () => {
    return (
        <Container>
            <StoreLink href={"//www.baidu.com"} target={"_blank"}>
                Visit Store
                <StoreLinkImg src={shopLinkimg}/>
            </StoreLink>

        </Container>
    );
};

export default VisitStore;