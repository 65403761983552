import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useNavigate} from "react-router-dom";


const Container=styled.div`
  width: 100%;
  height: 500px;

`
const Content=styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  margin:200px auto;
`
const Text=styled.div`
    font-size: 30px;
`
const Return=styled.span`
  color: blue;
  background-color: #bfa;
  cursor: pointer;
`
const UserNotFound = () => {
    const nav=useNavigate()
    const [count,setCount]=useState(6)
    useEffect(()=>{
        if (count<=0){
            nav("/")
        }
        setTimeout(()=>{
            setCount(prevState => prevState-1)
        },1000)
    },[count])

    return (
        <Container>
            <Content>
                <Text>user not found ,after {count}S jump to wallet Connect

                </Text>
                <Text>
                    <Return  onClick={()=>nav("/")}>return Immediately</Return>
                </Text>
            </Content>
        </Container>
    );
};

export default UserNotFound;