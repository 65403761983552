import React from 'react';
import styled from "styled-components";
import showCaseImg from '../../../../assets/icon_showcase@2x.png';
import showCaseItem from '../../../../imgs/azukiTest3.png'
import More from "../../../More";

const Container=styled.div`
  width: 450px;
  height: 114px;
  background: #FFFFFF;
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1000);
  border-radius: 16px;
  padding: 15px 20px 14px 19px;
  margin-bottom: 14px;
  @media screen and (max-width: 600px){
    width: 100%;
    height: 191px;
    overflow: hidden;
    padding-right: 11px;
  }
  @media screen and (min-width: 1500px){
    height: 182px;
    overflow: hidden;
    padding-right: 11px;
  }
  
`
const ShowCaseTitle=styled.div`
display: flex;
  justify-content: space-between;
`
const ShowLeft=styled.div`
  display: flex;
  align-items: center;
`
const ShowCaseText=styled.span`
    margin-left: 12px;

  color: #200D58;
`
const ShowCaseImg=styled.img`
width: 16px;
`

const ShowUl=styled.ul`
display: flex;
  margin-top: 11px;
  overflow: hidden;
  @media screen and (max-width: 600px){
    flex-wrap: wrap;
    margin-top: 0;
    height: 130px;
  }
  @media screen and (min-width: 1500px){
    flex-wrap: wrap;
    margin-top: 0;
    height: 130px;
  }
  
`
const ShowLi=styled.li`
  margin-right: 9px;
  @media screen and (max-width: 600px){
    margin-top: 9px;
  }
  @media screen and (min-width: 1500px){
    margin-top: 9px;
  }

`
const ShowImg=styled.img`
width: 51px;
  border-radius: 8px;
  @media screen and (max-width: 600px){
    width: 56px;
  }
`

const ShowList=[
    {
        id:1,
        img:showCaseItem,
    },
    {
        id:2,
        img:showCaseItem,
    },
    {
        id:3,
        img:showCaseItem,
    },
    {
        id:4,
        img:showCaseItem,
    },
    {
        id:5,
        img:showCaseItem,
    },
    {
        id:6,
        img:showCaseItem,
    },
    {
        id:7,
        img:showCaseItem,
    },
    {
        id:8,
        img:showCaseItem,
    },
    {
        id:9,
        img:showCaseItem,
    },
    {
        id:10,
        img:showCaseItem,
    },
    {
        id:11,
        img:showCaseItem,
    },
    {
        id:12,
        img:showCaseItem,
    },
    {
        id:13,
        img:showCaseItem,
    },

]

const ShowCase = () => {
    return (
        <Container>
            <ShowCaseTitle>
                <ShowLeft>
                    <ShowCaseImg src={showCaseImg}/>
                    <ShowCaseText>Showcase</ShowCaseText>
                </ShowLeft>
                <More  href={"//www.baidu.com"}/>
            </ShowCaseTitle>
                <ShowUl>
                    {ShowList.map((item)=>
                        <ShowLi key={item.id}><ShowImg src={item.img}/></ShowLi>
                    )}
                </ShowUl>
        </Container>
    );
};

export default ShowCase;