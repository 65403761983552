// eslint-disable-next-line
import React, {useContext} from 'react';
import styled from "styled-components";
import CopyAddress from "../../../CopyAddress";
import copyImg from '../../../../assets/btn_copy@2x.png'
import copy from "copy-to-clipboard";


const Container = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  width: 450px;
  height: 50px;
  background: #FFFFFF;
  color: black;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1000);
  border-radius: 16px;

  padding-left: 29px;
  margin-bottom: 14px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`
const IconImg = styled.img`
  width: 24px;
`
const OmitDiv = styled.div`

  width: 300px;
  margin-left: 41px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;

`
const OmitSpanLeft = styled.span`

`
const OmitSpanRight = styled.span`

`
const CopyContainer = styled.div`
  position: absolute;
  right: 20px;
`
const CopyImg = styled.img`
  width: 20px;
`
const LinkTreeItem = (props) => {
    let len = props.children.length;
    const preText = props.children.substring(0, 8);
    const afterText = props.children.substring(len - 4, len);

    const copyHandler = (e) => {
        // e.stopPropagation();
        e.preventDefault();
        copy(props.children);
    }
    return (
        <Container href={props.href} target={"_blank"}>
            <IconImg src={props.img}/>
            <OmitDiv>
                <OmitSpanLeft>{preText}</OmitSpanLeft>
                {(len > 36) && <><span>...</span>
                    <OmitSpanRight>{afterText}</OmitSpanRight></>
                }
            </OmitDiv>
            <CopyContainer>
                <CopyAddress>
                    <CopyImg src={copyImg} onClick={copyHandler}/>
                </CopyAddress>
            </CopyContainer>
        </Container>
    );
};

export default LinkTreeItem;