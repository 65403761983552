import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useAccount, useSignMessage} from "wagmi";
import {setSignatureMsg} from "../store/reducer/signatureMsg";
import axios from "axios";

const useFollowAction=()=>{
    //follow锁
    const [locked,setLocked]=useState(true)
    //签名是否成功
    let signSuccess=false;
    //关注是否成功
    const [followActionRes,setFollowActionRes]=useState(false)
    let timeNow = Date.now()
    //全局信息
    const signatureMsg = useSelector(state => state.signature);
    const dispatch = useDispatch();
    const addressMsg=useSelector(state => state.address)
    const account = useAccount()

    //发送follow请求
    const followApi=async (followType,signature,signTime,followerUid,followedUid,address)=>{
            try {
                axios.post(`https://xdojrp0yc0.execute-api.us-west-1.amazonaws.com/follow/${followType}`,
                    {
                        signature:signature,
                        SignTime:signTime,
                        address:address,
                        followedUid:followedUid,
                        followerUid:followerUid,
                    })
                    .then(res=> {
                        console.log("followData:",res.data)
                        if (res.data.statusCode===200){
                            setLocked(true)
                            setFollowActionRes(true)
                        }else{
                            setLocked(true)
                            console.log("wrong")
                        }
                    })
            }catch (e) {
            } finally {
                signSuccess=false;
            }
    }
    // eslint-disable-next-line
    const {data, error, isLoading, signMessageAsync} = useSignMessage({
        onSuccess(data,variables) {
            console.log("要存入环境变量的时间:",timeNow)
            dispatch(setSignatureMsg({
                timestamp: timeNow,
                signature: data
            }))
            // console.log("解析出来的钱包")
            // console.log(verifyMessage(timeNow.toString(),data))
            // console.log("解析出正确钱包的时间戳")
            // console.log(variables)
            // console.log("正确钱包")
            // console.log(verifyMessage(variables.message,data))
            signSuccess=true
        },
        onError(error) {
            setLocked(true)
            console.log('Error', error)
        },
    })

    const localSignature=async (time)=>{
        console.log("要参与签名的时间戳:",time)
        let signatureData = await signMessageAsync({message:time.toString()})
        localStorage.setItem(signatureMsg.localItem, JSON.stringify({
            timestamp: time,
            signature: signatureData
        }))
        console.log("我设置了本地签名")
    }
    //传入要关注的人的uid和关注情况
    const followHandler = async (followedUid,followed) => {
        //进入锁
        if (locked){
            //上锁
            setLocked(false)
            if (!account.address) {
                setLocked(true)
                return alert("请连接钱包")
            }
            //检查本地签名是否有效
            const localMsg = JSON.parse(localStorage.getItem(signatureMsg.localItem))
            console.log("查到的本地信息localMsg:",localMsg)
            timeNow = Date.now()
            if (localMsg) {
                //如果超时则需要根据时间戳签名
                console.log(timeNow)
                console.log(localMsg.timestamp)
                console.log(signatureMsg.survivalTime)
                if ((timeNow - localMsg.timestamp) > signatureMsg.survivalTime) {
                    try {
                        await localSignature(timeNow)
                    } catch (e) {
                        console.log(e)
                    }
                } else {
                    console.log("还没过期")
                    signSuccess=true
                }
            } else {
                try {
                    await localSignature(timeNow)
                } catch (e) {
                    console.log(e)
                }
            }
            console.log(signSuccess)
            if (signSuccess){
                let localSignatureMsg=JSON.parse(localStorage.getItem(signatureMsg.localItem))
                let followRes=await followApi(followed?"unfollow":"follow",localSignatureMsg.signature,localSignatureMsg.timestamp,addressMsg.WalletUid,followedUid,account.address)
                console.log("followRes:",followRes)
            }
        }else{
            console.log("locked")
            alert("please sign")
        }
    }
    return {followActionRes,setFollowActionRes,followHandler}
}

export default useFollowAction;