import React, {useRef} from 'react';
import styled from "styled-components";
// eslint-disable-next-line
import showCaseImg from "../../../../assets/icon_showcase@2x.png";
import shopImg from "../../../../assets/icon_shop@2x.png";
import More from "../../../More";
import shopItemsImg from '../../../../imgs/azuki4.png';
import ethIcon from '../../../../assets/ICON_ETH2@2x.png'
import VisitStore from "../../../VisitStore";
import pullDownImg from "../../../../assets/pulldown.png"

const Container=styled.div`
  width: 450px;
  height: 178px;
  background: #FFFFFF;
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1000);
  border-radius: 16px;
  padding: 15px 20px 12px 19px;
  @media screen and (max-width: 600px){
    width: 100%;
    padding-right: 11px;
    height: 313px;
  }
  @media screen and (min-width: 1500px){
    height: 240px;
    padding-right: 11px;
  }
  @media screen and (min-width: 1850px){
    height: 316px;
    padding-right: 11px;
  }
`

const ShopTitle=styled.div`
  display: flex;
  justify-content: space-between;
  
`
const ShopLeft=styled.div`
display: flex;
  align-items: center;
`
const ShopImg=styled.img`
  width: 20px;
`
const ShopText=styled.span`
  margin-left: 12px;
  color: #333;
`
const ShopUl=styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  height: 121px;
  overflow: hidden;
  @media screen and (max-width: 600px){
    width: 100%;
    height: 270px;
    margin-top: 0;
    overflow: hidden;
  }
  @media screen and (min-width: 1500px){
    height: 190px;
  }
  @media screen and (min-width: 1850px){
    height: 250px;
  }
`
const ShopLi=styled.li`
  position: relative;
  width: 201px;
  height: 54px;
  margin-right: 9px;
  margin-top: 11px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #ECECEC;
  cursor: pointer;
  
  @media screen and (min-width: 600px){
    &:nth-child(2n){
      margin-right: 0;
    }
    &:nth-child(1){
      margin-top: 0;
    }
    &:nth-child(2){
      margin-top: 0;
    }
  }
  @media screen and (max-width: 600px){
    width: 100%
  }

`
const ShopItemsImg=styled.img`
  position: absolute;
  left:-1px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 54px;
  border-radius: 8px;
`
const ShopItemMsg=styled.div`
  position: absolute;
  left: 59px;
  top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 86%;
  @media screen and (max-width: 600px){
   
  }
`
const ShopItemMsgTitle=styled.div`
font-size: 13px;
`
const ShopItemMsgContent=styled.div`
height: 19px;
  width: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 600px){
    width: 100%;
  }
`
const HideSpan1=styled.span`
  @media screen and (max-width: 600px){
    display: none;
  }
`
const HideSpan2=styled.span`
  margin-left: 10px;
  @media screen and (min-width: 600px){
    display: none;
  }
`

const ShopItemMsgSpan1=styled.span`

  font-size: 12px;
  font-family: Poppins-Regular, Poppins;
  font-weight: 400;
  color: #333333;

`
const ShopItemMsgSpan2=styled.span`

  font-size: 12px;
  font-family: ArialMT;
  color: #37159a;
  @media screen and (max-width: 600px){
    display: none;
  }

`
const ShopItemMsgSpan3=styled.span`
    display: flex;
  align-items: center;

  font-size: 14px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #202020;

`
const EthIcon=styled.img`
    width: 8px;
  margin-right: 2px;
`
const BuyBtn=styled.button`
  position: absolute;

  right: 11px;
  bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 19px;
  border-radius: 4px;
  border: 1px solid;
  background: #fff;
  color: rgba(139, 59, 248, 1);
  z-index: 0;
  @media screen and (min-width: 600px){
    display: none;
  }
  &::before{
    content: "";
    position: absolute;
    inset: 0px;
    z-index: -1;
    margin: 10px;
    border-radius: inherit;
    
    background: linear-gradient(139deg, rgba(57, 66, 250, 1), rgba(139, 59, 248, 1));
  }
`

const MorePosition=styled.div`
@media screen and (max-width: 600px){
  display: none;
}
`
const VisitStorePosition=styled.div`
  margin-right: 11px;
  @media screen and (min-width: 600px){
    display: none;
  }
`
const PullDown=styled.div`
position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  @media screen and (min-width: 600px){
    display: none;
  }
`
const PullDownImg=styled.img`

`
const Shop = () => {
    const ShopList=[
        {
            id:1,
            img:shopItemsImg,
            title:"Syd Mead Progr",
            nth:"#0001",
            isAbsolute:true,
            no:1,
            amount:10,
            price:3.99
        },
        {
            id:2,
            img:shopItemsImg,
            title:"Syd Mead Progrrrr",
            nth:"#0002",
            isAbsolute:false,
            no:1,
            amount:10,
            price:7.02
        },
        {
            id:3,
            img:shopItemsImg,
            title:"Syd Mead Progrrrrr",
            nth:"#0003",
            isAbsolute:false,
            no:1,
            amount:10,
            price:2.66
        },
        {
            id:4,
            img:shopItemsImg,
            title:"Syd Mead Progrrr",
            nth:"#0004",
            isAbsolute:true,
            no:1,
            amount:10,
            price:14.99
        },
        {
            id:5,
            img:shopItemsImg,
            title:"Syd Mead Progrrr",
            nth:"#0004",
            isAbsolute:true,
            no:1,
            amount:10,
            price:14.99
        },
        {
            id:6,
            img:shopItemsImg,
            title:"Syd Mead Progrrr",
            nth:"#0004",
            isAbsolute:true,
            no:1,
            amount:10,
            price:14.99
        },
        {
            id:7,
            img:shopItemsImg,
            title:"Syd Mead Progrrr",
            nth:"#0004",
            isAbsolute:true,
            no:1,
            amount:10,
            price:14.99
        },
        {
            id:8,
            img:shopItemsImg,
            title:"Syd Mead Progrrr",
            nth:"#0004",
            isAbsolute:true,
            no:1,
            amount:10,
            price:14.99
        },

    ];
    const container=useRef();
    const pullDown=useRef();
    const shopUl=useRef();

    const expandHandler=()=>{
        console.log(container.current.style.height);
        container.current.style.height="450px";
        pullDown.current.style.display="none";
        shopUl.current.style.height="400px";
    }

    const shopLinkHandler=()=>{
        window.open("//www.baidu.com");
    }
    return (
        <Container ref={container}>
            <ShopTitle>
                <ShopLeft>
                    <ShopImg src={shopImg}/>
                    <ShopText>Shop</ShopText>
                </ShopLeft>
                <MorePosition>
                    <More href={"//www.baidu.com"}/>
                </MorePosition>
                <VisitStorePosition>
                    <VisitStore/>
                </VisitStorePosition>
            </ShopTitle>
            <ShopUl ref={shopUl}>
                {ShopList.map((item)=>
                    <ShopLi key={item.id} onClick={shopLinkHandler}>
                        <ShopItemsImg src={item.img}/>
                        <ShopItemMsg>
                            <ShopItemMsgTitle>
                                {item.title}
                                <HideSpan2>
                                    <ShopItemMsgSpan1>{item.nth}</ShopItemMsgSpan1>
                                </HideSpan2>
                            </ShopItemMsgTitle>
                            <ShopItemMsgContent>
                                <HideSpan1>
                                    <ShopItemMsgSpan1>{item.nth}</ShopItemMsgSpan1>
                                </HideSpan1>
                                {item.isAbsolute&&<ShopItemMsgSpan2>{item.no}/{item.amount}</ShopItemMsgSpan2>}
                                <ShopItemMsgSpan3>
                                    <EthIcon src={ethIcon}/>
                                    {item.price}
                                </ShopItemMsgSpan3>
                            </ShopItemMsgContent>
                        </ShopItemMsg>
                        <BuyBtn>Buy</BuyBtn>
                    </ShopLi>
                )}
                <PullDown ref={pullDown} onClick={expandHandler}>
                    <PullDownImg src={pullDownImg}/>
                </PullDown>
            </ShopUl>
        </Container>
    );
};

export default Shop;