import {ethType, nameType, twitterNameType, uidType} from "../types/UserTypeDefinition";

export default function userTypeCheck (str){
    //twitter_musk
    //12345678
    //hehe
    //0xdb20

    //是否为推特用户
    var twitterUser=/^twitter[_]./
    //是否为钱包地址
    var walletAddress=/0x[0-9a-fA-F]{40}/
    //是否为uid
    var uid=/^[0-9]{8}$/

    if (twitterUser.test(str)){
        return {
            type:twitterNameType,
            name:str.substring(8)
        }
    }
    if (walletAddress.test(str)){
        return {
            type: ethType,
            name:str
        }
    }
    if (uid.test(str)){
        return {
            type:uidType,
            name:str
        }
    }
    //最后为用户名
    return {
        type: nameType,
        name:str
    }


}