import { createSlice} from "@reduxjs/toolkit";

const addressSlice = createSlice({
    name: 'address',
    initialState: {
        Address:"",
        WalletUid:0
    },
    reducers: {
        setAddressMsg(state, action) {
            state.Address = action.payload;
        },
        setUidMsg(state, action) {
            state.WalletUid = action.payload;
        }
    }
});
export const {setAddressMsg,setUidMsg} = addressSlice.actions;
export const {reducer: addressReducer} = addressSlice;