import React from 'react';
import Header from "./pages/Header";
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";


const { chains, provider ,webSocketProvider} = configureChains(
    [chain.mainnet, chain.polygon, chain.optimism, chain.arbitrum],
    [
        jsonRpcProvider({
            rpc: (chain) => ({
                // http: chain.rpcUrls.default,
                http: "https://eth-rinkeby.alchemyapi.io/v2/TrV45RvXdr025n8XUskersaTbPmVzVfo",
            }),
        }),
        alchemyProvider({
            apiKey: '_gg7wSSi0KMBsdKnGVfHDueq6xMB9EkC',
        })
    ]
);

const { connectors } = getDefaultWallets({
    appName: "My RainbowKit App",
    chains
});

const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
    webSocketProvider,
});


const Layout = (props:any) => {
    return (
    <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains}>
            <Header/>
            {props.children}
        </RainbowKitProvider>
    </WagmiConfig>
    );
};

export default Layout;