import React from 'react';
import styled from "styled-components";

const MoreLink= styled.a`
  
  color: #999999;
  @media screen and (max-width: 600px){
    padding-right: 9px;
  }

  @media screen and (min-width: 1500px){
    padding-right: 9px;
  }
`

const More = (props) => {
    return (
        <MoreLink href={props.href} target={"_blank"}>
            more +
        </MoreLink>
    );
};

export default More;