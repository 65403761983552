import React from 'react';
import styled from "styled-components";

const External=styled.div`
  position: relative;
  overflow: hidden;
`
const PreSpan=styled.span`
    
`
const AfterSpan=styled.span`
    
`
const Address = (props) => {
    let pre=props.pre|2;
    let after=props.after|4;
    let len=props.children.length;
    const preText=props.children.substring(0,pre);
    const afterText=props.children.substring(len-after,len);

    return (
        <External style={props.style}>
            <PreSpan>
                {preText}
            </PreSpan>
            <span>...</span>
            <AfterSpan>{afterText}</AfterSpan>
        </External>
    );
};

export default Address;