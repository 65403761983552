import React from 'react';
import Layout from "./Layout";
import WalletConnect from "./pages/WalletConnect";
import MainPage from "./pages/MainPage";
import {Route, Routes} from "react-router-dom";



function App() {
    // //读本地localStorage
    // const signatureMsg=useSelector(state => state.signature)
    // const localSignatureMsg=JSON.parse(localStorage.getItem(signatureMsg.localItem))
    // const dispatch=useDispatch()
    // //如果有则设为全局变量
    // if (localSignatureMsg){
    //     dispatch(setSignatureMsg(localSignatureMsg))
    // }

  return (
    <Layout>
      <Routes>
        <Route path={"/"} element={<WalletConnect/>}/>
        <Route path={"/:id"} element={<MainPage/>}/>
      </Routes>
    </Layout>
  );
}

export default App;
