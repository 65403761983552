import React, { useEffect, useState } from "react";
import styled from "styled-components";
import sniIcon from "../../assets/btn_copy@2x.png";
import FollowNum from "../FollowNum";
import FollowBtn from "../FollowBtn";
import CopyAddress from "../CopyAddress";
import { Modal } from "antd";
import copy from "copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useGetFollowNumberQuery } from "../../store/api/followNumberApi";
import { setFollowers, setFollowings } from "../../store/reducer/homeMsg";
// eslint-disable-next-line
import { useAccount, useSignMessage } from "wagmi";
import "./FollowListPop.css";

import FollowListModal from "./FollowListModal";
import axios from "axios";
import useFollowAction from "../../hooks/useFollowAction";

const Container = styled.div`
  position: absolute;
  top: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and(max-width: 600px) {
  }
`;
const Msg = styled.div`
  @media screen and (max-width: 600px) {
    min-width: 252px;
  }
`;
const Name = styled.div`
  font-size: 30px;
  margin-bottom: 3px;
`;
const AsterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #666666;

  margin-bottom: 12px;
`;
const AsterID = styled.div`
  margin-right: 8px;
`;
const SniImg = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;
const FollowNums = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
`;
const BtnContainer = styled.div`
  @media screen and (max-width: 600px) {
    position: absolute;
    top: -45px;
    right: 0;
  }
`;

const PersonalMsg = () => {
  //全局信息
  const homeMsg = useSelector((state) => state.home);
  const addressMsg = useSelector((state) => state.address);
  const account = useAccount();
  //粉丝列表信息
  const [followerList, setFollowerList] = useState([]);

  // following list info
  const [followingList, setFollowingList] = useState([])

  //加载状态
  const dispatch = useDispatch();
  //是否已经关注
  const [followed, setFollowed] = useState(false);

  const {
    followActionRes,
    setFollowActionRes,
    followHandler,
  } = useFollowAction();

  const copyHandler = () => {
    copy(homeMsg.Address);
  };
  //粉丝列表弹出
  const [visible, setVisible] = useState(false);

  // myFollowlist popup
  const [followingVisible, setFollowingVisible] = useState(false);

  // follow title
  const [followTitle, setFollowTitle] = useState("")  

  //查看粉丝列表
  const followersListHandler = async () => {
    setVisible(true);
    setFollowTitle("Followers")
    axios
      .get(
        `https://hmmm54s7u9.execute-api.us-west-1.amazonaws.com/follow/getAllFollwers?uid=${homeMsg.AsterNumID}&walletUid=${addressMsg.WalletUid}`
      )
      .then((res) => {
        setFollowerList(res.data.content);
      });
  };

  // check myFollowlist
  const myFollowlistHandler = async () => {
    setFollowTitle("Followings")
    setFollowingVisible(true);
    axios.get(
      `https://hmmm54s7u9.execute-api.us-west-1.amazonaws.com/follow/getallfolloweds?uid=${homeMsg.AsterNumID}&walletUid=${addressMsg.WalletUid}`
    )
    .then((res) => {
      setFollowingList(res.data.content)
    })
  };

  const { data: addressData, isSuccess } = useGetFollowNumberQuery(
    homeMsg.AsterNumID
  );
  //发起关注请求
  const myFollowHandler = async () => {
    followHandler(homeMsg.AsterNumID, followed);
  };
  // 设置跟随数量
  useEffect(() => {
    if (isSuccess) {
      console.log(addressData, isSuccess);
      dispatch(setFollowers(addressData.followerNumber));
      dispatch(setFollowings(addressData.followedNumber));
    }
  }, [addressData, isSuccess, followed]);
  // 是否已经关注
  useEffect(() => {
    if (account.address && homeMsg.AsterNumID !== 0) {
      axios
        .get(
          `https://hmmm54s7u9.execute-api.us-west-1.amazonaws.com/follow/isFollowing?followerAdd=${account.address}&followedUid=${homeMsg.AsterNumID}`
        )
        .then((res) => {
          console.log("isFollowingData:", res.data);
          if (res.data.status === 200) setFollowed(true);
        });
    }
  }, [account.address, homeMsg.AsterNumID]);
  //根据follow的回调决定是否更新跟随状态
  useEffect(() => {
    if (followActionRes) {
      setFollowed((prevState) => !prevState);
      setFollowActionRes(false);
    }
  }, [followActionRes]);
  return (
    <Container>
      <Msg>
        <Name>{homeMsg.name || "Unnamed"}</Name>
        <AsterContainer>
          <AsterID>
            Aster ID: {homeMsg.AsterID} ({homeMsg.AsterNumID})
          </AsterID>
          <CopyAddress>
            <SniImg src={sniIcon} onClick={copyHandler} />
          </CopyAddress>
        </AsterContainer>
        <FollowNums wrapClassName={"followListWrap"}>
          <FollowNum
            wrapClassName={"followListWrap"}
            onClick={myFollowlistHandler}
            amount={homeMsg.Followings}
            content={"Followings"}
          />
          <FollowNum
            wrapClassName={"followListWrap"}
            onClick={followersListHandler}
            amount={homeMsg.Followers}
            content={"Followers"}
          />
        </FollowNums>
        <Modal
          title={followTitle}
          centered
          visible={visible}
          onCancel={() => setVisible(false)}
          width={700}
          footer={null}
          style={{ textAlign: "center", height: "500px" }}
          bodyStyle={{ padding: "10px" }}
          wrapClassName={"followListWrap"}
        >
          <FollowListModal followList={followerList} title={followTitle}/>
        </Modal>

        <Modal
          title={followTitle}
          centered
          visible={followingVisible}
          onCancel={() => setFollowingVisible(false)}
          width={700}
          footer={null}
          style={{ textAlign: "center", height: "500px" }}
          bodyStyle={{ padding: "10px" }}
          wrapClassName={"followListWrap"}
        >
          <FollowListModal followList={followingList} title={followTitle}/>
        </Modal>
      </Msg>
      {homeMsg.Priority ? (
        ""
      ) : (
        <BtnContainer>
          <FollowBtn follow={followed} onClick={myFollowHandler} />
        </BtnContainer>
      )}
    </Container>
  );
};

export default PersonalMsg;
