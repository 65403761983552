// eslint-disable-next-line
import React, {useContext, useState} from 'react';
import icon1 from '../../../../assets/logo_eth@2x.png';
import icon2 from '../../../../assets/logo_opensea@2x.png';
import icon3 from '../../../../assets/twitter@2x.png';
import icon4 from '../../../../assets/facebook@2x.png';
import icon5 from '../../../../assets/tiktok@2x.png';
import styled from "styled-components";
import LinkTreeItem from "./LinkTreeItem";

import {useSelector} from "react-redux";

const Container = styled.div`
  margin-right: 20px;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }
`

const LinkTree = () => {
    const homeMsg = useSelector(state => state.home);
    // eslint-disable-next-line
    const [eth, setEth] = useState(true);
    // eslint-disable-next-line
    const [opensea, setOpensea] = useState(true);
    // eslint-disable-next-line
    const [twitter, setTwitter] = useState(true);
    // eslint-disable-next-line
    const [facebook, setFacebook] = useState(true);
    // eslint-disable-next-line
    const [tiktok, setTiktok] = useState(true);

    const linktrees = [
        {
            image: icon1,
            display: eth,
            msg: homeMsg.LinkTree.eth,
            href: "https://ethereum.org/en/"
        },
        {
            image: icon2,
            display: eth,
            msg: homeMsg.LinkTree.opensea,
            href: "https://opensea.io/"
        },
        {
            image: icon3,
            display: eth,
            msg: homeMsg.LinkTree.twitter,
            href: "https://twitter.com/asterprotocol"
        },
        {
            image: icon4,
            display: eth,
            msg: homeMsg.LinkTree.facebook,
            href: "https://www.facebook.com/"
        },
        {
            image: icon5,
            display: eth,
            msg: homeMsg.LinkTree.tiktok,
            href: "https://www.tiktok.com/"
        },
    ]
    return (
        <Container>
            {linktrees.map((item) =>
                <LinkTreeItem
                    key={item.image}
                    img={item.image}
                    href={item.href}
                >{item.msg}</LinkTreeItem>)
            }
        </Container>
    );
};

export default LinkTree;