import React from 'react';
import styled from "styled-components";
import { Spin } from 'antd';

const Container=styled.div`
  width: 100%;
  height: 500px;

`
const Content=styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin:200px auto;
`
const Text=styled.div`
    font-size: 30px;
`
const UserSearching = () => {
    return (
        <Container>
            <Content>
                <Text>user searching...</Text>
                <Spin size={"large"}/>
            </Content>
        </Container>
    );
};

export default UserSearching;