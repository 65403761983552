import { createSlice} from "@reduxjs/toolkit";

const signatureSlice = createSlice({
    name: 'signature',
    initialState: {
        //localStorage
        localItem:"signatureMsg",
        // survivalTime:3600000,//一小时
        survivalTime:60000,//10秒
        msg:{
            timestamp:0,
            signature:""
        }
    },
    reducers: {
        setSignatureMsg(state, action) {
            console.log("环境变了中准备替换的值")
            console.log(action.payload)
            state.msg.timestamp= action.payload.timestamp;
            state.msg.signature=action.payload.signature;
        }
    }
});
export const {setSignatureMsg} = signatureSlice.actions;
export const {reducer: signatureReducer} = signatureSlice;