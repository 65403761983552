import menuImg from '../assets/icon_menu@2x.png';
import closeImg from '../assets/icon_close@2x.png'

export const Nomal={
    starDisplay:true,

    searchDisplay:false,
    searchImgDisplay:true,
    searchWidth:"33vw",
    searchImgLeft:"-18vw",


    menuImg:menuImg,
    menuImgWidth:"27px",

    addressOut:false
}
export const SearchExpand={
    starDisplay:false,

    searchDisplay:true,
    searchWidth: "88vw",
    searchImgDisplay:true,
    searchImgLeft:"40px",

    menuImg:closeImg,
    menuImgWidth:"27px",

    addressOut:false
}
export const MenuOut={
    starDisplay:true,

    searchDisplay:false,
    // searchWidth: "90vw",
    searchImgDisplay:false,

    menuImg:closeImg,
    menuImgWidth:"27px",

    addressOut:true
}
