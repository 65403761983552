import React from 'react';
import styled from "styled-components";
import iconImg from "../../imgs/azuki2.png";

const Container=styled.div`
position: relative;
  width: 136px;
  height: 136px;
  top: -94px;
  border: 4px solid #FFFFFF;
  border-radius: 50%;

  overflow: hidden;
  @media screen and (max-width: 600px){
    width: 100px;
    height: 100px;
    top: -49px;
    border-radius: 12px;
  }
`

const IconImg=styled.img`
width: 136px;
  @media screen and (max-width: 600px){
    width: 100px;
  }
`
const HeadIcon = () => {
    return (
        <Container>
            <IconImg src={iconImg}/>
        </Container>
    );
};

export default HeadIcon;