import React, {ReactFragment, ReactNode, useState} from 'react';
import {Tooltip} from "antd";
import 'antd/dist/antd.css' ;





const CopyAddress = ({children}:{children:ReactNode}) => {
    const [clicked, setClicked] = useState(false);
    const [hovered, setHovered] = useState(false);
    const clickHandler = () => {
        setClicked(true);
        setHovered(false);
    }
    const CopiedLeaveHandler = () => {
        setClicked(false);
    }
    const enterHandler = () => {
        setHovered(true);
    }
    const CopyLeaveHandler = () => {
        setHovered(false);
    }


    return (
        <div>
            <Tooltip
                trigger={"none"}
                title="copied!"
                visible={clicked}
                overlayInnerStyle={{backgroundColor: "black", color: "white", borderRadius: "8px"}}
                // @ts-ignore
                onClick={clickHandler}
                onMouseLeave={CopyLeaveHandler}
            >
                <Tooltip
                    trigger={"none"}
                    title="copy"
                    visible={hovered}
                    // @ts-ignore
                    onMouseEnter={enterHandler}
                    onMouseLeave={CopiedLeaveHandler}
                    overlayInnerStyle={{backgroundColor: "black", color: "white", borderRadius: "8px"}}
                >
                    {children}
                </Tooltip>
            </Tooltip>
        </div>
    );
};

export default CopyAddress;