import React, { useEffect, useState } from "react";
import styled from "styled-components";
import topImg from "../../assets/icon_top@2x.png";
import ethImg from "../../assets/ICON_ETH2@2x.png";
import asterImg from "../../assets/nav_logo@2x.png";
import FollowBtn from "../FollowBtn";
import useFollowAction from "../../hooks/useFollowAction";

const FollowListModalContainer = styled.div`
  position: relative;
  padding: 0 0 27px 0;
`;

const TopContent = styled.div`
  display: flex;
  position: absolute;
  left: 5px;
  top: 0;
`;
const TopImg = styled.img`
  height: 22px;
`;
const TopText = styled.span`
  font-size: 16px;
  line-height: 22px;
  color: #666666;
  margin-left: 10px;
`;
const FollowListUl = styled.ul`
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: ${(props) =>
    props.hasFollowList ? "20px 0 0 28px" : "23px 0 18px 28px"};
  top: 30px;
  margin: auto;
`;
const FollowerListLi = styled.li`
  width: 290px;
  height: 30px;
  margin-right: 20px;
  margin-bottom: 25px;
  position: relative;
`;
const BtnContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
const UserImg = styled.img`
  width: 22px;
  margin-right: 15px;
`;
const FollowListModal = (props) => {
  const {
    followActionRes,
    setFollowActionRes,
    followHandler,
  } = useFollowAction();

  // Used to keep track of the list of followers follow state
  const [isFollowedList, setIsFollowedList] = useState();

  // The index of the most current follower being clicked
  const [updatedUsrId, setUpdatedUsrId] = useState();

  // The follow state of the most current follower being clicked
  const [updatedFollowed, setUpdatedFollowed] = useState();

  // error   写一个empty list 显示
  useEffect(() => {
    console.log("followlist", props.followList);
    props.followList
      ? setIsFollowedList(props.followList.map((item) => item.isFollowed))
      : setIsFollowedList([]);
  }, [props.followList]);

  const clickFollowHandler = async (index, followed, usrId) => {
    followHandler(usrId, followed);
    setUpdatedUsrId(index);
    setUpdatedFollowed(!followed);
  };

  //根据follow的回调决定是否更新跟随状态
  useEffect(() => {
    if (followActionRes) {
      let tempList = isFollowedList;
      tempList[updatedUsrId] = updatedFollowed;
      setIsFollowedList(tempList);
      setFollowActionRes(false);
      console.log(isFollowedList);
    }
  }, [followActionRes, updatedUsrId, updatedFollowed]);
  return (
    <FollowListModalContainer>
      <TopContent>
        <TopImg src={topImg} />
        <TopText>Top50</TopText>
      </TopContent>
      <FollowListUl hasFollowList={props.followList ? true : false}>
        {props.followList ? 
        
          props.followList.map((item, key) => (
            <FollowerListLi key={item.uid}>
              <UserImg src={item.prefer_show ? asterImg : ethImg} />
              {item.uid}
              <BtnContainer>
                <FollowBtn
                  height="30px"
                  onClick={() =>
                    clickFollowHandler(key, isFollowedList[key], item.uid)
                  }
                  follow={isFollowedList[key]}
                />
              </BtnContainer>
            </FollowerListLi>
          ))
         : props.title === "Followers" ? 
          <div>Waiting someone follow</div>
         : 
          <div>Start follow someone</div>
        }
      </FollowListUl>
    </FollowListModalContainer>
  );
};

export default FollowListModal;
