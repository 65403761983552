import React from 'react';
import styled from "styled-components";
import LinkTree from "./linktree/LinkTree";
import NftDisplay from "./nftDisplay/NftDisplay";

const Container=styled.div`
position: absolute;
  width: 100%;
  top: 220px;
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
`

const List = () => {
    return (
        <Container>
            <LinkTree/>
            <NftDisplay/>
        </Container>
    );
};

export default List;