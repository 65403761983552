import React from 'react';
import styled from "styled-components";

const Container=styled.div`
margin-right: 36px;
  cursor:pointer;
`
const Num=styled.div`
font-size: 24px;
  line-height: 40px;
  color: #200D58;
`
const Content=styled.div`

  font-size: 15px;
  color: #8A80A6;
  line-height: 25px;
`
const FollowNum = (props) => {
    return (
        <Container onClick={props.onClick}>
            <Num>{props.amount}</Num>
            <Content>{props.content}</Content>
        </Container>
    );
};

export default FollowNum;