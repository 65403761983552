import React from 'react';
import styled from "styled-components";
import ShowCase from "./ShowCase";
import Shop from "./Shop";
import VisitStore from "../../../VisitStore";

const Container=styled.div`
  position: relative;
  @media screen and (max-width: 600px){
    width: 100%;
  }
`
const VisitStorePosition=styled.div`
position: absolute;
  right: 20px;
  margin-top: 5px;
  font-size: 12px;
  line-height: 17px;

  @media screen and (max-width: 600px){
    display: none;
  }
`

const NftDisplay = () => {
    return (
        <Container>
            <ShowCase/>
            <Shop/>
            <VisitStorePosition>
                <VisitStore/>
            </VisitStorePosition>
        </Container>
    );
};

export default NftDisplay;