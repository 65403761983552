import React from 'react';
import styled from "styled-components";


const Section=styled.section`
width: 100%;
  height: 160px;
  background-color: #acb;
`

const PvtBackground = () => {
    return (
            <Section>

            </Section>
    );
};

export default PvtBackground;