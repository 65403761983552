import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";

export const followNumberApi=createApi({
    reducerPath:'followNumberApi',
    baseQuery:fetchBaseQuery({
        baseUrl:"https://hmmm54s7u9.execute-api.us-west-1.amazonaws.com/follow/",
    }),

    endpoints(build) {
        return{
            getFollowNumber:build.query({
                query(account) {
                    return `getFollowerNumber?uid=${account}`
                }
            })
        }
    }
});

export const {
    useGetFollowNumberQuery
}=followNumberApi;

export default followNumberApi;