import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom"

import styled from "styled-components";
// eslint-disable-next-line 
import {useAccount, useConnect} from "wagmi";
import {ethType} from "../types/UserTypeDefinition";
import {useConnectModal} from "@rainbow-me/rainbowkit";
import {useDispatch} from "react-redux";
import {setUidMsg} from "../store/reducer/addressMsg";



const ButtonContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

`
const ConnectBtn=styled.button`
  background-color: #0E76FD;
  border-radius: 6px;
  color:white;
  width: 200px;
  height: 50px;
  border: 0px;
  font-size: 24px;
  cursor: pointer;
`
const LoadingPage = styled.div`
  font-size: 40px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const WalletConnect = () => {
    const [loading, setLoading] = useState(false);
    const [registerMSg, setRegister] = useState({
        message: "",
        status: 0
    });
    const {openConnectModal}=useConnectModal();
    //钱包uid
    const dispatch=useDispatch()

    const account = useAccount();
    const nav = useNavigate();
    //拉取注册信息
    const registerMsg = async () => {
        console.log("拉取注册信息")
        console.log("account.address",account.address)
        try {
            const response = await fetch(`https://yvxniuz5pe.execute-api.us-west-1.amazonaws.com/default/register?account=${account.address}&type=${ethType}`, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                }
            })
            const data = await response.json();
            setRegister(data);
            console.log("data.user.uid:",data.user.uid)
            dispatch(setUidMsg(data.user.uid))
            console.log("查过数据库")
            console.log(data)
        } catch (e) {
            console.log("发生错误:",e)
        }
    }
    //如果账户登录
    useEffect( () => {
        if (account.isConnected) {
            setLoading(true)
            try {
                //如果还没登录
                if (registerMSg.status === 0) {
                     registerMsg();
                } else if (registerMSg.status === 200) {
                    nav(account.address,{replace:true})
                } else if (registerMSg.status === 400) {
                    //如果
                    switch (registerMSg.message) {
                        case "account already exist":
                            nav(account.address,{replace:true})
                            break;
                        default :
                            console.log("意外情况")
                    }
                }
            } catch (e) {
                console.log(e)
            } finally {
                setLoading(false)
            }
        }
        //如果账户连接,去查找数据库有没有该账号,有则跳转到相应页面
    }, [account])

    return (
        <>
            {loading ?
                <LoadingPage>登录中</LoadingPage>
                :
                <ButtonContainer>
                    <ConnectBtn onClick={openConnectModal}>Connect</ConnectBtn>
                </ButtonContainer>
            }
        </>
    );
};

export default WalletConnect;