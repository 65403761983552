import React from 'react';
import styled from "styled-components";

const Btn=styled.button`
  height: ${props=>props.height||"50px"};
  width: ${props=>props.follow?"120px":"90px"};

  border-radius: 12px;
  background: ${props=>props.follow?"#EFEBF5":"#F1EFF5"};

  border: ${props=>props.follow?"none":"1px solid #E0DBED"};
  font-size: 15px;
  color: ${props=>props.follow?"#C3B5E2":"#62517E"};
  @media screen and (max-width: 600px){
    height: 36px;
  }
`

const FollowBtn = (props) => {

    return (
        <Btn onClick={props.onClick} follow={props.follow} height={props.height}>
            {props.follow?"following":"follow"}
        </Btn>
    );
};

export default FollowBtn;