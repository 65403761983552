import {configureStore} from "@reduxjs/toolkit";
import {homeReducer} from "./reducer/homeMsg";
import {addressReducer} from "./reducer/addressMsg";
import followNumberApi from "./api/followNumberApi";

import {signatureReducer} from "./reducer/signatureMsg";


const store = configureStore({
    reducer: {
        home: homeReducer,
        address:addressReducer,
        signature:signatureReducer,
        [followNumberApi.reducerPath]:followNumberApi.reducer,
    },
    middleware:getDefaultMiddleware =>
        getDefaultMiddleware().concat(followNumberApi.middleware)
});

export default store;