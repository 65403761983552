import { createSlice} from "@reduxjs/toolkit";

export const blankMsg={
    name: "",
    AsterID: "",
    AsterNumID: 0,
    type:0,
    Followings: 0,
    Followers: 0,
    Priority:false,
    LinkTree: {
        eth: "",
        opensea: "",
        twitter: "",
        facebook: "",
        tiktok: ""
    }
}

const homeSlice = createSlice({
    name: 'home',
    initialState: {
        name: "",
        AsterID: "",
        AsterNumID: 0,
        //页面用户来自于哪里 -1:找不到 0:默认 10：以太坊 11：solana 100.twitter名 101.twitter_uid
        type:0,
        Followings: 0,
        Followers: 0,
        //是否为自己的个人主页
        Priority:false,
        LinkTree: {
            eth: "",
            opensea: "",
            twitter: "",
            facebook: "",
            tiktok: ""
        }
    },
    reducers: {
        setUserType(state,action){
          state.type=action.payload
        },
        setFollowers(state,action){
            state.Followers=action.payload;
        },
        setFollowings(state,action){
            state.Followings=action.payload;
        },
        setPriority(state,action){
            state.Priority=action.payload;
        },
        setUserMsg(state,action){
            state.name=action.payload.user_name||"";
            state.AsterNumID=action.payload.uid||0;
            //如果没有注册途径
            state.type=action.payload.areacode||0;
            state.LinkTree.eth=action.payload.eth_address||"";
            state.LinkTree.twitter=action.payload.twitter_handle||"";
        },

    }
});
export const {setFollowings,setPriority,setUserType,setUserMsg,setFollowers} = homeSlice.actions;
export const {reducer: homeReducer} = homeSlice;

// Address:"0xdb039Ec0a1dfa2AEe7EdD49199450A8cFfB41E51",
// name: "Pavel Pechenkov",
// AsterID:"mike.star",
// AsterNumID:33445243341,
// Followings:208,
// Followers:560213,
// LinkTree:{
//     eth:"0xdb039Ec0a1dfa2AEe7EdD49199450A8cFfB41E51",
//     opensea:"@0xdb039Ec0a1dfa2AEe7EdD49199450A8cFfB41E51",
//     twitter:"@jaychou",
//     facebook:"@ Pavel",
//     tiktok:"@ Pavel"
// }